import { render, staticRenderFns } from "./EmployeeDetail.vue?vue&type=template&id=51f76249"
import script from "./EmployeeDetail.vue?vue&type=script&lang=js"
export * from "./EmployeeDetail.vue?vue&type=script&lang=js"
import style0 from "./EmployeeDetail.vue?vue&type=style&index=0&id=51f76249&prod&lang=less&scope=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "EmployeeDetail.vue"
export default component.exports