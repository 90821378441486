<template>
  <div class="employeeDetail positionr" v-loading="loading">
    <!-- 面包屑导航区 -->
    <el-breadcrumb>
      <el-breadcrumb-item class="pointer" :to="{ path: '/employee' }"
        >人员管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>人员管理详情</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 头像更换 -->
    <div class="avatar-uploader positiona"></div>

    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <!-- tabbar -->
        <el-col :span="3">
          <div id="navigation">
            <ul>
              <li
                class="menu-item text-cut"
                v-for="(item, i) in tabs"
                :key="item"
                :class="seeThis == i ? 'active' : ''"
              >
                <a href="javascript:void(0)" @click="goAnchor(i)">{{ item }}</a>
              </li>
            </ul>
          </div>
        </el-col>
        <!-- tabbar对应内容 -->
        <el-col :span="21">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row style="display: flex; flex-wrap: wrap">
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="保存基本信息和扩展属性"
                          placement="bottom"
                        >
                          <el-button
                            type="primary"
                            size="small"
                            @click="submitForm('ruleForm')"
                            >保存</el-button
                          >
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form
                  :model="ruleForm"
                  :rules="rules"
                  ref="ruleForm"
                  label-width="150px"
                  class="ruleForm"
                  size="mini"
                >
                  <el-row style="display: flex; flex-wrap: wrap">
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="姓 名" prop="name">
                        <el-input v-model="ruleForm.name"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="电 话" prop="phone">
                        <el-input v-model="ruleForm.phone"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="员工编号" prop="ad">
                        <el-input v-model="ruleForm.ad"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="性 别" prop="sex">
                        <el-select v-model="ruleForm.sex">
                          <el-option label="男" value="1"></el-option>
                          <el-option label="女" value="2"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="状 态">
                        <el-select
                          v-model="ruleForm.status"
                          placeholder="请选择"
                          class="wp100"
                        >
                          <el-option label="在职" :value="1"></el-option>
                          <el-option label="离职" :value="2"></el-option>
                          <el-option label="产期休假" :value="3"></el-option>
                          <el-option label="代理" :value="4"></el-option>
                          <el-option label="供应商" :value="5"></el-option>
                          <el-option label="外部" :value="6"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="员工类型">
                        <el-select
                          v-model="ruleForm.employeeType"
                          placeholder="请选择"
                          class="wp100"
                        >
                          <el-option label="内部员工" :value="1"></el-option>
                          <el-option label="外部员工" :value="2"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="应急联系人">
                        <el-input
                          v-model="ruleForm.escapContacts"
                          placeholder="请输入"
                          class="wp100"
                        >
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="婚姻状况" prop="maritalstatus">
                        <el-input v-model="ruleForm.maritalStatus"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="24">
                      <el-form-item label="单双休设置">
                        <el-radio v-model="ruleForm.restType" :label="1"
                          >单休</el-radio
                        >
                        <el-radio v-model="ruleForm.restType" :label="2"
                          >双休</el-radio
                        >
                        <el-radio v-model="ruleForm.restType" :label="3"
                          >单双休</el-radio
                        >
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="24">
                      <el-form-item label="推送设置">
                        <el-switch
                          v-model="ruleForm.signNotice"
                          active-color="#13ce66"
                          inactive-color="#ff4949"
                          :active-value="1"
                          :inactive-value="0"
                        >
                        </el-switch>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
            <!-- 子系统账户 -->

            <!-- 与组织的关系 -->
            <div class="p1" id="a2">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="24">
                      <div class="flexCenter">
                        <div>
                          <span class="line"></span> <span>与组织的关系</span>
                        </div>
                        <el-button
                          plain
                          class="add-column-btn"
                          @click="setselectOrgDialog"
                          ><i class="el-icon-plus fw900 f16"></i> 新
                          增</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <!-- 表格 -->
                <el-table
                  v-loading="loading"
                  :data="orgTableData"
                  border
                  fit
                  height="240px"
                  highlight-current-row
                  style="width: 100%"
                >
                  <el-table-column
                    label="序号"
                    align="center"
                    type="index"
                  ></el-table-column>
                  <el-table-column label="组织名称" align="center">
                    <template slot-scope="scope">
                      <span>{{ scope.row.deptName }}</span>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="createDt" align="center" label="创建时间">

                  </el-table-column> -->
                  <!-- <el-table-column label="组织类型" align="center">
                    <template slot-scope="scope">
                      <span>{{ scope.row.organType }}</span>
                    </template>
                  </el-table-column> -->
                  <!-- <el-table-column
                    label="操作"
                    align="center"
                    class-name="small-padding"
                    fixed="right"
                    width="100"
                  >
                    <template slot-scope="scope">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="删除"
                        placement="bottom"
                      >
                        <i
                          class="el-icon-close textblue f16 fw700"
                          @click="delOrg(scope.row.id)"
                        ></i>
                      </el-tooltip>
                    </template>
                  </el-table-column> -->
                </el-table>
              </div>
            </div>
            <!-- 与岗位的关系 -->
            <div class="p1" id="a3">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="24">
                      <div class="flexCenter">
                        <div>
                          <span class="line"></span> <span> 与岗位的关系</span>
                        </div>
                        <el-button
                          plain
                          class="add-column-btn"
                          @click="setselectPostDialog"
                          ><i class="el-icon-plus fw900 f16"></i> 新
                          增</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <!-- 表格 -->
                <el-table
                  v-loading="loading"
                  :data="postTableData"
                  border
                  fit
                  height="240px"
                  highlight-current-row
                  style="width: 100%"
                >
                  <el-table-column
                    label="序号"
                    align="center"
                    type="index"
                  ></el-table-column>
                  <el-table-column label="岗位名称" align="center">
                    <template slot-scope="scope">
                      <span>{{ scope.row.postName }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="岗位描述" align="center">
                    <template slot-scope="scope">
                      <span>{{ scope.row.description }}</span>
                    </template>
                  </el-table-column>

                  <!-- <el-table-column
                    label="操作"
                    align="center"
                    class-name="small-padding"
                    fixed="right"
                    width="100"
                  >
                    <template slot-scope="scope">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="删除"
                        placement="bottom"
                      >
                        <i
                          class="el-icon-close textblue f16 fw700"
                          @click="delPost(scope.row.id)"
                        ></i>
                      </el-tooltip>
                    </template>
                  </el-table-column> -->
                </el-table>
              </div>
            </div>
            <!-- 与权限的关系 -->
            <div class="p1" id="a4">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="24">
                      <div class="flexCenter">
                        <div>
                          <span class="line"></span> <span>与权限的关系</span>
                        </div>
                        <el-button plain class="add-column-btn" @click="setAuth"
                          ><i class="el-icon-plus fw900 f16"></i> 新
                          增</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <!-- 表格 -->
                <el-table
                  v-loading="loading"
                  :data="roleTableData"
                  border
                  fit
                  height="240px"
                  highlight-current-row
                  style="width: 100%"
                >
                  <el-table-column label="角色名称" align="center">
                    <template slot-scope="scope">
                      <span>{{ scope.row.roleName }}</span>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column label="角色描述" align="center">
                    <template slot-scope="scope">
                      <span>{{ scope.row.desInfo }}</span>
                    </template>
                  </el-table-column> -->
                  <el-table-column label="时间" align="center">
                    <template slot-scope="scope">
                      <span>{{ scope.row.createDt }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="操作"
                    align="center"
                    class-name="small-padding"
                    fixed="right"
                    width="100"
                  >
                    <template slot-scope="scope">
                      <!-- <el-tooltip
                      class="item"
                      effect="dark"
                      content="保存"
                      placement="bottom"
                    >
                      <i class="el-icon-s-claim textblue f16"></i>
                    </el-tooltip> -->
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="删除"
                        placement="bottom"
                      >
                        <i
                          class="el-icon-close textblue f16 fw700"
                          @click="delRole(scope.row.id)"
                        ></i>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
                <!-- 新增按钮 -->
                <!-- <div class="handle-btn wp100">
                  <el-button
                    plain
                    class="add-column-btn"
                    :disabled="disabledFalg"
                    @click="setAuth"
                    ><i class="el-icon-plus fw900 f16"></i> 新 增</el-button
                  >
                </div> -->
              </div>
            </div>
            <!--  -->
            <!-- 约谈记录 -->
            <!-- <div class="p1" id="a5">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="24">
                     <div class="flexCenter">
                      <div> <span class="line"></span>
                      <span>约谈记录</span></div>
                       <el-button
                    plain
                    class="add-column-btn"
                    @click="addTalk"
                    ><i class="el-icon-plus fw900 f16"></i> 新 增</el-button
                  >
                     </div>
                    </el-col>

                  </el-row>
                </div>
              </div>
              <div class="header-input">

                <el-table
                  v-loading="loading"
                  :data="talkTable"
                  border
                  fit
                  height="240px"
                  highlight-current-row
                  style="width: 100%"
                >
                  <el-table-column label="约谈类型" align="center">
                    <template slot-scope="scope">
                      <span>{{ scope.row.reward }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="约谈人" align="center">
                    <template slot-scope="scope">
                      <span>{{ scope.row.talkor }}</span>
                    </template>
                  </el-table-column>
                        <el-table-column label="约谈时间" align="center">
                    <template slot-scope="scope">
                      <span>{{ scope.row.talkDt }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="约谈事件" align="center">
                    <template slot-scope="scope">
                      <span>{{ scope.row.content }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="操作"
                    align="center"
                    class-name="small-padding"
                    fixed="right"
                    width="100"
                  >
                    <template slot-scope="scope">
                      <el-tooltip
                      class="item"
                      effect="dark"
                      content="保存"
                      placement="bottom"
                    >
                      <i class="el-icon-s-claim textblue f16"></i>
                    </el-tooltip> -->
            <!-- <el-tooltip
                        class="item"
                        effect="dark"
                        content="编辑"
                        placement="bottom"
                      >
                        <i
                          class="el-icon-edit textblue f16 fw700"
                          @click="editTalk(scope.row)"
                        ></i>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
<el-pagination
      @size-change="handleSizeChangeTalk"
      @current-change="handleCurrentChangeTalk"
      :current-page="talkQueryInfo.currPage"
      :page-sizes="[20, 50, 100, 200]"
      :page-size="talkQueryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="talkTotal">
    </el-pagination> -->
            <!-- 新增按钮 -->
            <!-- <div class="handle-btn wp100">
                  <el-button
                    plain
                    class="add-column-btn"
                    :disabled="disabledFalg"
                    @click="setAuth"
                    ><i class="el-icon-plus fw900 f16"></i> 新 增</el-button
                  >
                </div> -->
            <!-- </div>
            </div> -->
            <!--  -->
          </div>
        </el-col>
      </el-row>

      <!-- 选择组织弹窗 -->
      <el-dialog
        title="选择组织"
        :visible.sync="selectOrgDialog"
        class="selectorDialog"
        id="selectorOrgDialog"
      >
        <div class="search-condition box">
          <el-row type="flex" justify="end">
            <el-col :span="16">
              <el-input placeholder="组织名称" class="mr10">
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </el-col>
          </el-row>
        </div>
        <div class="org-selected">
          <el-row>
            <el-col :xs="24" :sm="12" :md="12" :lg="12">
              <div style="margin-top: 5px">
                <el-tree
                  default-expand-all
                  :data="treeData"
                  node-key="deptCode"
                  :current-node-key="defaultId"
                  :props="defaultProps"
                  ref="tree"
                  show-checkbox
                  :highlight-current="true"
                  :check-strictly="true"
                  :check-on-click-node="true"
                  @check="treeNodeClick"
                >
                </el-tree>
              </div>
            </el-col>
            <el-col
              :xs="24"
              :sm="12"
              :md="12"
              :lg="12"
              class="selectedUl pr10 bbox"
            >
              <p class="wp100 bbox flex justify-between textblue">
                <span>已选择</span>
                <span class="pointer" @click="empty"
                  ><i class="el-icon-delete"></i> 清空</span
                >
              </p>
              <div class="selectedUl-box">
                <ul>
                  <li
                    class="clearfix"
                    v-for="(item, i) in multipleSelection"
                    :key="i"
                  >
                    <span class="floatLeft rightOrderBox">{{
                      item.deptName
                    }}</span>
                    <i
                      class="el-icon-close floatRight iconOrganization iconblue fw700 f15"
                      @click="del(item.deptCode, i)"
                    ></i>
                    <!-- <i
                      class="el-icon-sort-up floatRight iconOrganization iconblue fw700"
                      @click="changeup(item.id, i)"
                    ></i>
                    <i
                      class="el-icon-sort-down floatRight iconOrganization iconblue fw700"
                      @click="changedown(item.id, i)"
                    ></i> -->
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="confirmOrg">确 认</el-button>
          <el-button @click="selectOrgDialog = false">取 消</el-button>
        </div>
      </el-dialog>

      <!-- 岗位信息弹窗 -->
      <el-dialog
        title="岗位信息"
        :visible.sync="selectPostDialog"
        class="selectorDialog"
      >
        <div class="search-condition box">
          <el-row type="flex" justify="end">
            <el-col :span="16">
              <el-input placeholder="岗位名称" class="mr10">
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </el-col>
          </el-row>
        </div>

        <div class="org-selected">
          <el-row>
            <el-col :xs="24" :sm="14" :md="14" :lg="14">
              <div class="pl10 pr10 bbox">
                <div class="checkbox-group borb">
                  <el-radio-group
                    v-model="checkedItems"
                    @change="handleCheckedItemsChange"
                  >
                    <div
                      class="checkboxItem"
                      v-for="item in selectPostData"
                      :key="item.postCode"
                    >
                      <el-radio :label="item">{{ item.postName }}</el-radio>
                    </div>
                  </el-radio-group>
                </div>
              </div>
            </el-col>
            <el-col
              :xs="24"
              :sm="10"
              :md="10"
              :lg="10"
              class="selectedUl pr10 bbox"
            >
              <p class="wp100 bbox flex justify-between textblue">
                <span>已选择</span>
                <!-- <span class="pointer" @click="emptyPostInfo"
                  ><i class="el-icon-delete"></i> 清空</span
                > -->
              </p>
              <div class="selectedUl-box" style="height: 240px !important">
                <ul>
                  <li
                    class="clearfix"
                    v-for="(item, i) in multipleSelection2"
                    :key="i"
                  >
                    <span class="floatLeft rightOrderBox">{{
                      item.postName
                    }}</span>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="confirmPost">确 认</el-button>
          <el-button @click="selectPostDialog = false">取 消</el-button>
        </div>
      </el-dialog>

      <!-- 选择权限弹窗 -->
      <el-dialog
        title="选择权限"
        :visible.sync="selectAuthorityDialog"
        class="selectorDialog"
      >
        <div class="search-condition box">
          <el-row type="flex" justify="end">
            <el-col :span="16">
              <el-input placeholder="描述/权限组" class="mr10">
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </el-col>
          </el-row>
        </div>

        <div class="org-selected">
          <el-row>
            <el-col :xs="24" :sm="16" :md="16" :lg="16">
              <el-table
                ref="multipleTable"
                :data="selectAuthorityData"
                tooltip-effect="dark"
                style="width: 100%; margin-top: 0"
                @selection-change="handleSelectionChange"
                border
                fit
                height="354px"
                highlight-current-row
              >
                <el-table-column type="selection" width="55" align="center">
                </el-table-column>
                <el-table-column type="index" label="序号" align="center">
                </el-table-column>
                <el-table-column
                  prop="roleName"
                  label="角色名称"
                  align="center"
                >
                </el-table-column>
                <el-table-column prop="desInfo" label="角色说明" align="center">
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <!-- <el-pagination
                @size-change="handleSizeChangeAuthority"
                @current-change="handleCurrentChangeAuthority"
                :current-page="queryInfoAuthority.currPage"
                :page-size="queryInfoAuthority.pageSize"
                layout="total, prev, pager, next"
                :total="totleAuthority"
                class="ml10"
                style="margin-bottom: 15px"
              ></el-pagination> -->
            </el-col>
            <el-col
              :xs="24"
              :sm="8"
              :md="8"
              :lg="8"
              class="selectedUl pr10 bbox"
            >
              <p class="wp100 bbox flex justify-between textblue">
                <span>已选择</span>
                <span class="pointer" @click="emptyAuthority"
                  ><i class="el-icon-delete"></i> 清空</span
                >
              </p>
              <div class="selectedUl-box">
                <ul>
                  <li
                    class="clearfix"
                    v-for="(item, i) in multipleSelection3"
                    :key="item.roleCode"
                  >
                    <span class="floatLeft rightOrderBox">{{
                      item.roleName
                    }}</span>
                    <i
                      class="el-icon-close floatRight iconOrganization iconblue fw700 f15"
                      @click="delAuthority(item.roleCode, i)"
                    ></i>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="confirmAuthority">确 认</el-button>
          <el-button @click="selectAuthorityDialog = false">取 消</el-button>
        </div>
      </el-dialog>
      <!-- 约谈记录弹窗 -->
      <el-dialog title="约谈记录" :visible.sync="talkDialog" width="700px">
        <el-form label-width="90px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="约谈类型">
                <el-select v-model="talkForm.reward">
                  <el-option label="奖励" value="奖励"></el-option>
                  <el-option label="惩罚" value="惩罚"></el-option>
                  <el-option label="日常约谈" value="日常约谈"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="约谈人">
                <el-input v-model="talkForm.talkor"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="约谈时间">
                <el-date-picker
                  v-model="talkForm.talkDt"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="事件内容">
                <el-input
                  :autosize="{ minRows: 2, maxRows: 88 }"
                  v-model="talkForm.content"
                  type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="附件" class="upload-col">
                <el-upload
                  :file-list="fileList"
                  :action="$uploadURL"
                  list-type="picture-card"
                  :on-success="handleSuccess"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog append-to-body :visible.sync="dialogVisibleInner">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveTalk">确 认</el-button>
          <el-button @click="talkDialog = false">取 消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    // 自定义手机号规则
    var checkMobile = (rule, value, callback) => {
      const regMobile = /^1[34578]\d{9}$/;
      if (regMobile.test(value)) {
        return callback();
      }
      // 返回一个错误提示
      callback(new Error("请输入合法的手机号码"));
    };
    return {
      parentCode: "",
      talkType: "add",
      talkTotal: 0,
      talkQueryInfo: {
        currPage: 1,
        pageSize: 20,
        condition: {
          userCode: this.$route.query.userCode,
        },
      },

      talkTable: [],
      dialogVisibleInner: false,
      dialogImageUrl: "",

      fileList: [],
      talkForm: {
        id: "",
        userCode: "",
        reward: "",
        talkor: "",
        content: "",
        talkDt: "",
      },
      talkDialog: false,
      seeThis: 0,
      tabs: [
        "基本信息",
        "与组织的关系",
        "与岗位的关系",
        "与权限的关系",
        // "约谈记录",
      ], //  // "扩展属性",
      // "汇报关系",
      ruleForm: {
        id: "",
        name: "",
        phone: "",
        ad: "",
        sex: "1",
        status: 1,
        employeeType: 1,
        escapContacts: "",
        maritalStatus: "",
        restType: 1,
        signNotice: 1,
      },
      rules: {
        name: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
        ad: [{ required: true, message: "员工编号不能为空", trigger: "blur" }],
      },
      tableData: [],
      id: "",
      userCode: "",
      disabledFalg: false,
      loading: true,
      imageUrl: "",
      userName: "",
      orgTableData: [], //与组织的关系表格数据
      treeData: [], // 组织树形结构数据
      defaultProps: {
        children: "childrens",
        label: "deptName",
      },
      defaultKyes: [], //选中节点
      defaultId: "", //当前节点
      multipleSelection: [], // 组织选中列表
      selectOrgDialog: false, //选择组织弹窗
      postTableData: [], //与岗位的关系表格数据
      selectPostDialog: false, // 选择岗位弹窗
      selectPostData: [], // 岗位信息表格数据
      multipleSelection2: [], // 岗位选中列表
      checkAll: false, //全选状态
      checkedItems: [], // 默认选中
      isIndeterminate: true, //表示 checkbox 的不确定状态，一般用于实现全选的效果
      queryInfoPost: {
        // 当前页数
        page: 1,
        // 每页显示多少数据
        size: 20,
      },
      totlePost: "",
      roleTableData: [], // 与权限的关系表格数据
      selectAuthorityDialog: false, //选择权限弹窗
      selectAuthorityData: [], // 选择权限列表
      multipleSelection3: [], // 权限选中列表
      queryInfoAuthority: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 100,
      },
      totleAuthority: "",
    };
  },
  // watch: {
  //   "talkQueryInfo.condition.useCode": {
  //     handler(newValue, oldValue) {
  //       this.getTalkTable();
  //     },
  //   },
  // },
  created() {
    this.id = this.$route.query.id;
    if (this.id == undefined || this.id == "") {
      this.userCode = this.randomString(11);
      this.disabledFalg = true;
    } else {
      this.userCode = this.$route.query.userCode;
      this.disabledFalg = false;
      this.getDataById();
    }

    this.userName = JSON.parse(localStorage.getItem("userInfo")).userInfo.name;
    this.getTreeData();

    this.getRoleTableData();
  },
  methods: {
    // 表单提交
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.ruleForm));
          data.objectCode = JSON.parse(
            localStorage.getItem("userInfo")
          ).userInfo.objectCode;
          data.objectName = JSON.parse(
            localStorage.getItem("userInfo")
          ).userInfo.objectName;
          data.loginName = data.phone;
          // data.loginName = data.loginName
          data.creator = this.userName;
          data.updator = this.userName;
          data.userType = "1";
          data.roleUserList = this.roleTableData;
          if (this.postTableData.length == 0) {
            this.$message.info("请选择与岗位的关系");
            return;
          }
          data.postName = this.postTableData[0].postName;
          data.postDesc = this.postTableData[0].description;
          // data.password = this.$md5("123456");
          if (this.orgTableData.length == 0) {
            this.$message.info("请选择与组织的关系");
            return;
          }
          data.deptCode = this.orgTableData[0].deptCode;
          data.deptName = this.orgTableData[0].deptName;
          let url = this.id ? "/userInfo/update" : "/userInfo/add";
          let res = await this.$http.post(url, data);
          if (res.data.code == 200) {
            this.$notify.success({
              title: "提示",
              message: "增加成功",
              showClose: true,
            });
            this.$router.push({
              path: "/employee",
            });
          } else {
            this.$message.error(res.data.message);
          }
        } else {
          return false;
        }
      });
    },
    setselectOrgDialog() {
      this.selectOrgDialog = true;
      this.$nextTick(() => {
        if (this.orgTableData.length == 0) {
          this.$refs.tree.setCheckedKeys([]);
        } else {
          this.$refs.tree.setCheckedKeys([this.orgTableData[0].deptCode]);
          this.multipleSelection = this.$refs.tree.getCheckedNodes();
        }
      });
    },
    setselectPostDialog() {
      this.selectPostDialog = true;
      this.getPostInfoData();
    },
    treeNodeClick(node, list) {
      this.parentCode = node.parentCode;
      this.multipleSelection = [node];
      this.$refs.tree.setCheckedKeys([node.deptCode]);
    },
    editTalk(row) {
      this.talkType = "edit";
      for (let i in this.talkForm) {
        this.talkForm[i] = row[i];
      }
      let img = row.annexUrl.split(",").filter((item) => item != "");
      this.fileList = img.map((item) => {
        return { name: item, url: item };
      });
      console.log(this.fileList, "fileList");
      this.talkDialog = true;
    },
    // async getTalkTable() {
    //   let res = await this.$http.post("talk/list", this.talkQueryInfo);
    //   console.log(res, "sstalkQueryInfos");
    //   if (res.data.code == 200) {
    //     this.talkTable = res.data.data.data;
    //     this.talkTotal = res.data.data.count;
    //   }
    // },
    async saveTalk() {
      let data = JSON.parse(JSON.stringify(this.talkForm));
      data.userName = this.ruleForm.name;
      data.annexUrl = this.fileList.map((item) => item.url).join(",");

      data.userCode = this.$route.query.userCode;
      console.log(data);
      if (this.talkType == "add") {
        delete data.id;
      }
      let res = await this.$http.post("talk/save", data);
      if (res.data.code == 200) {
        this.$message.success("保存成功");
        this.talkDialog = false;
        this.fileList = [];
        // this.getTalkTable();
      }
    },
    handleSuccess(res, file) {
      this.fileList.push({ name: res.data.url, url: res.data.url });
    },
    handleRemove(res, fileList) {
      let index = this.fileList.findIndex(
        (item) => item.url == res.response.data.url
      );
      this.fileList.splice(index, 1);
      console.log(res, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleInner = true;
    },
    addTalk() {
      (this.talkForm = {
        id: "",
        userCode: "",
        reward: "",
        talkor: "",
        content: "",
        talkDt: "",
      }),
        (this.talkType = "add");
      this.talkDialog = true;
    },
    setAuth() {
      this.selectAuthorityDialog = true;
      this.getRoleInfo();
    },
    goAnchor(index) {
      this.$nextTick(() => {
        document.querySelector("#a" + (index + 1)).scrollIntoView({
          behavior: "smooth", // 平滑过渡
          block: "start",
        });
      });
    },
    handleScroll() {
      // console.log(document.querySelector(".menu-content").scrollTop)
    },

    // 监听排序
    sortChange() {},

    //头像
    errorHandler() {
      return true;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },

    // 与组织的关系删除事件
    async delOrg(id) {
      var that = this;
      const confirmResult = await that
        .$confirm("确定删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return that.$notify.info({
          title: "提示",
          message: "已取消删除",
          showClose: true,
        });
      } else {
        that.$http.delete("/userOrgan/" + id).then(function (response) {
          that.$notify.success({
            title: "提示",
            message: "成功删除1条数据",
            showClose: true,
          });
        });
      }
    },

    // 与岗位的关系删除事件
    async delPost(id) {
      var that = this;
      const confirmResult = await that
        .$confirm("确定删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return that.$notify.info({
          title: "提示",
          message: "已取消删除",
          showClose: true,
        });
      } else {
        that.$http.delete("/userPost/" + id).then(function (response) {
          that.$notify.success({
            title: "提示",
            message: "成功删除1条数据",
            showClose: true,
          });
          that.getPostData(that.userCode);
        });
      }
    },

    // 与权限的关系删除事件
    async delRole(id) {
      var that = this;
      const confirmResult = await that
        .$confirm("确定删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return that.$notify.info({
          title: "提示",
          message: "已取消删除",
          showClose: true,
        });
      } else {
        that.$http
          .post("engineeringRoleUser/delete", { id })
          .then(function (response) {
            that.$notify.success({
              title: "提示",
              message: "成功删除1条数据",
              showClose: true,
            });
            that.getRoleTableData();
          });
      }
    },

    // 清空
    empty() {
      this.multipleSelection = [];
      this.$refs.tree.setCheckedKeys([]);
    },
    //删除
    del(id, i) {
      this.$refs.tree.setChecked(id);
      this.multipleSelection.splice(i, 1);
    },
    // 组织选择确认
    confirmOrg() {
      this.orgTableData = this.multipleSelection.map((item) => {
        return { deptCode: item.deptCode, deptName: item.deptName };
      });
      this.selectOrgDialog = false;
      // var organCodeList = [];
      // for (var i in this.multipleSelection) {
      //   organCodeList.push(this.multipleSelection[i].organCode);
      // }
      // this.selectOrgDialog = false;
      // // this.orgTableData.push(this.multipleSelection[i]);
      // var that = this;
      // that.$http
      //   .post("/userOrgan/add", {
      //     organCode: organCodeList.toString(),
      //     userCode: that.userCode,
      //   })
      //   .then(function (response) {
      //     that.orgTableData = [];
      //     that.getOrgData(that.userCode);
      //   })
      //   .catch(function (error) {});
    },

    // 全选
    handleCheckAllChange(val) {
      var selectPostData = [];
      for (var i in this.selectPostData) {
        selectPostData.push(this.selectPostData[i].postName);
      }
      this.checkedItems = val ? selectPostData : [];
      this.multipleSelection2 = val ? this.selectPostData : [];
      this.isIndeterminate = false;
    },
    // 选择
    handleCheckedItemsChange(value) {
      this.multipleSelection2 = [value];
    },
    // 清空选择的岗位信息
    emptyPostInfo() {
      this.multipleSelection2 = [];
      this.checkAll = false;
      this.checkedItems = [];
    },
    // 删除选择的岗位信息
    delPostInfo(postName, i) {
      this.checkedItems = this.checkedItems.filter((item, i) => {
        if (item != postName) {
          return true;
        } else {
          return false;
        }
      });
      this.multipleSelection2.splice(i, 1);
      this.getPostInfoData();
    },
    // 岗位信息确认
    confirmPost() {
      this.postTableData = this.multipleSelection2;
      this.selectPostDialog = false;
    },

    // 监听 pagesize改变的事件
    handleSizeChangePost(newSize) {
      // console.log(newSize)
      this.queryInfoPost.size = newSize;
      this.getPostInfoData();
    },
    // 监听 页码值 改变事件
    handleCurrentChangePOst(newSize) {
      // console.log(newSize)
      this.queryInfoPost.page = newSize;
      this.getPostInfoData();
    },

    //权限选择器选择事件
    handleSelectionChange(val) {
      this.multipleSelection3 = val;
      //console.log(this.multipleSelection3);
    },

    // 清空
    emptyAuthority() {
      this.multipleSelection3 = [];
      this.$refs.multipleTable.clearSelection();
    },
    //删除
    delAuthority(roleCode, i) {
      console.log(i, "iiiiiiiiiiiiiii");
      this.selectAuthorityData.forEach((row) => {
        if (row.roleCode == roleCode) {
          this.$refs.multipleTable.toggleRowSelection(row, false);
        }
      });
      // this.multipleSelection3.splice(i, 1);
    },
    // 选择权限确认
    async confirmAuthority() {
      // let roleUserList=[]
      // this.multipleSelection3.forEach(item=>{
      //   roleUserList.push({userCode:item.roleCode})
      // })

      // console.log(that.userCode);
      let res = await this.$http.post("engineeringRoleUser/addRoleList", {
        userCode: this.userCode,
        roleUserList: this.multipleSelection3,
      });
      if (res.data.code == 200) {
        this.$notify.success({
          title: "提示",
          message: "保存成功",
          showClose: true,
        });
        this.getRoleTableData();
        this.selectAuthorityDialog = false;
      } else {
        this.$message.error(res.data.message);
      }
    },
    // 监听 pagesize改变的事件
    handleSizeChangeAuthority(newSize) {
      // console.log(newSize)
      this.queryInfoAuthority.pageSize = newSize;
      this.getRoleInfo();
    },
    // 监听 页码值 改变事件
    handleCurrentChangeAuthority(newSize) {
      // console.log(newSize)
      this.queryInfoAuthority.currPage = newSize;
      this.getRoleInfo();
    },
    handleSizeChangeTalk(newSize) {
      this.queryInfoTalk.pageSize = newSize;
      this.getTalkData();
    },
    handleCurrentChangeTalk(newSize) {
      this.queryInfoTalk.currPage = newSize;
      this.getTalkData();
    },

    // 根据id获取用户信息
    async getDataById() {
      if (this.id != undefined || this.id != "") {
        let res = await this.$http.post("/userInfo/queryOne", { id: this.id });
        if (res.data.code == 200) {
          this.postTableData = [
            {
              postName: res.data.data.postName,
              description: res.data.data.postDesc,
            },
          ];
          this.talkForm.userCode = this.$route.query.userCode;
          this.talkQueryInfo.condition.useCode = res.data.data.useCode;
          let { deptCode, deptName } = res.data.data;
          this.orgTableData = [{ deptCode, deptName }];
          for (let i in this.ruleForm) {
            this.ruleForm[i] = res.data.data[i];
          }
        } else {
          this.$message.error(res.data.message);
        }
      }
    },

    // 获取树形结构数据
    getTreeData() {
      var that = this;
      that.treeData = [];
      that.$http
        .post("/engineeringDept/queryTreeList", {
          groupCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
            .engineeringGroup.groupCode,
        })
        .then(function (response) {
          //console.log(response);
          if (response.data.code == 200) {
            that.$nextTick(() => {
              that.defaultId = response.data.data[0].organCode;
            });
            that.treeData = response.data.data;
          } else {
            that.$message({
              message: response.data.message,
              duration: 1500,
              type: "error",
            });
          }
        });
    },

    // 获取岗位信息数据
    async getPostInfoData() {
      let res = await this.$http.post("post/queryList", {
        groupCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .engineeringGroup.groupCode,
        deptCode: this.parentCode,
      });
      if (res.data.code == 200) {
        this.selectPostData = res.data.data;
        // this.totlePost = res.data.data.count;
      } else {
        this.$message({
          message: res.data.message,
          duration: 1500,
          type: "error",
        });
      }
    },

    // 获取权限信息数据
    getRoleInfo() {
      var that = this;
      that.$http
        .post("engineeringRole/queryList", {})
        .then(function (response) {
          //console.log(response.data.data.data);
          if (response.data.code == 200) {
            that.selectAuthorityData = response.data.data;
            console.log(that.selectAuthorityData, that.roleTableData);
            let arr = [];
            that.roleTableData.forEach((row) => {
              that.selectAuthorityData.forEach((item) => {
                if (row.roleCode == item.roleCode) {
                  arr.push(item);
                }
              });
            });
            console.log(arr, "arrr");
            setTimeout(() => {
              arr.forEach((row) => {
                that.$refs.multipleTable.toggleRowSelection(row, true);
              });
            }, 400);
            // that.totleAuthority = response.data.data.count;
          } else {
            that.$message({
              message: response.data.message,
              duration: 1500,
              type: "error",
            });
          }
        });
    },

    // 获取与权限的关系表格数据
    getRoleTableData() {
      var that = this;
      that.roleTableData = [];
      // console.log(that.userCode)
      that.$http
        .post("engineeringRoleUser/queryListByUser", {
          userCode: that.userCode,
        })
        .then(function (response) {
          if (response.data.code == 200) {
            that.roleTableData = response.data.data;
          } else {
            that.$message({
              message: response.data.message,
              duration: 1500,
              type: "error",
            });
          }
        });
    },

    // 获取随机字符串
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
  },
  mounted() {
    var that = this;
    // this.getTalkTable();
    this.$el.querySelector(".menu-content").onscroll = () => {
      that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  },
};
</script>

<style lang="less" scope>
.h50 {
  height: 50px;
}

.el-button.add-column-btn {
  font-size: 13px;
  background-color: transparent;
  color: #008eff;
  margin-top: -1px;
  position: relative;
  z-index: 100;
}
.employeeDetail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }
  .avatar-uploader {
    top: -16px;
    right: 20px;
    z-index: 10;
  }
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;
      li {
        height: 50px;
        line-height: 50px;
        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }
      li.active,
      li:hover {
        background-color: #fff;
      }
    }
    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;
      .p1 {
        margin-bottom: 20px;
      }
      .header-search {
        .title {
          height: 50px;
          font-weight: 700;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;
          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }
          .el-input {
            width: 247px;
          }
          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }
      .header-input {
        font-size: 13px;
      }
      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }
        .el-table {
          margin-top: 10px;
        }
      }
    }
  }
  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }
  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }
  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}
.flexCenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.upload-col {
  .el-upload-list__item {
    transition: none !important;
  }
  .el-upload--picture-card {
    height: 100px;
    width: 100px;
    line-height: 100px;
  }
}
.el-upload-list--picture-card .el-upload-list__item {
  height: 100px;
  width: 100px;
}
</style>
